import Component from "@base/Component";
import Step1 from "@pages/wantToHelp/Step1";
import Step2 from "@pages/wantToHelp/Step2";
import Step3 from "@pages/wantToHelp/Step3";
import Step4 from "@pages/wantToHelp/Step4";
import {Steps} from 'antd';
import {
    LoadingOutlined
} from '@ant-design/icons';

const {Step} = Steps;

class Index extends Component {

    data = {
        step: 0,
        payment: {
            method: 0,
            email: null,
            methods: {
                1: {url: 'tel'},
                2: {url: 'qiwi'},
                3: {url: 'kassa'},
                4: {url: 'transfer'},
            },
            remoteMethodsID: {
                3: 1,
                2: 2,
            },
            amount: 100
        },
        text: {
            step1: {
                title: (
                    <h3>
                        {this.t('Способ внесения пожертвования')}
                    </h3>
                ),
            },
            step2: {
                title: (
                    <h3>
                        {this.t('Сумма пожертвования')}
                    </h3>
                ),
            },
            step3: {
                title: this.t('Перевод средств'),
                req: this.t('Перевод средст по реквизитам'),
            },
            step4: {
                title: this.t('Мобильные платежи'),
            }
        }
    }

    /**
     * redirect to payment system
     */
    goToPaymentSystem = () => {
        this.set({
            step: 100
        })

        let remoteID = this.data.payment.remoteMethodsID[this.data.payment.method];

        let $params = {
            email: this.data.payment.email,
            method: remoteID,
            amount: this.data.payment.amount,
            lang: this.app().data.lang,
            redirect: this.app().data.siteUrl + '/payment-result'
        }

        let path = '/transaction/request?amount={amount}&lang={lang}&redirect={redirect}&system={method}&email={email}';

        let $action = this.parseParams(path, $params)

        this.API.get($action, (res) => {
            if (typeof res.redirect === 'string')
                window.location.href = res.redirect
        })
    }

    /**
     * set step
     * @param step
     */
    setStep = (step) => {
        let payment = this.data.payment
        let d = new Date()
        let ms = d.getTime() + d.getMilliseconds()
        let em = d.getFullYear() + '-' + d.getMonth() + '-' + d.getDate() + '-' + ms + '_payment@whp.ru';
        payment.email = em
        this.set({payment: payment})
        this.set({step: step})
    }

    /**
     * set payment method
     * @param method
     */
    setPaymentMethod = (nr) => {
        let payment = this.data.payment;
        payment['method'] = nr;
        this.set({payment: payment})
    }

    /**
     * set email
     * @param email
     */
    setEmail = (email) => {
        let payment = this.data.payment;
        payment['email'] = email;
        this.set({payment: payment})
    }

    /**
     * set payment amount
     * @param amount
     */
    setAmount = (amount) => {
        let payment = this.data.payment;
        payment['amount'] = amount;
        this.set({payment: payment})
    }

    render() {
        return (
            <>
                {
                    (this.data.payment.method === 1 && this.data.step === 1) ? (
                        <Steps direction="vertical" size="large" current={this.data.step}>
                            <Step title={(
                                <h3>
                                    {this.data.text.step4.title}
                                </h3>
                            )} description={<Step4 caller={this} step={1}/>}/>
                        </Steps>
                    ) : ((this.data.step > 1 && this.data.payment.method === 4) ?
                        (
                            <Steps direction="vertical" size="large" current={this.data.step}>
                                <Step title={(
                                    <h3>
                                        {this.data.text.step3.req}
                                    </h3>
                                )} description={<Step3 caller={this} step={2}/>}/>
                            </Steps>
                        )
                        :
                        (
                            <Steps direction="vertical" size="large" current={this.data.step}>
                                <Step title={this.data.text.step1.title} description={<Step1 step={0} caller={this}/>}/>
                                <Step title={this.data.text.step2.title} description={<Step2 step={1} caller={this}/>}/>
                                <Step title={(
                                    <h3>
                                        {this.data.step === 100 ? (
                                            <>
                                                {this.t('Переход на оплату')}
                                                <div style={{display: 'inline-block'}}>
                                                    &nbsp;&nbsp;&nbsp;
                                                    <LoadingOutlined/>
                                                </div>
                                            </>
                                        ) : this.data.text.step3.title}

                                    </h3>
                                )} description={''}/>
                            </Steps>
                        ))
                }
            </>
        )

    }
}

export default Index;
