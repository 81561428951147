import Component from "@base/Component";
import {Col, Row} from "antd";

class Step1 extends Component {

    /** @type {./Index} */
    caller = this.props.caller

    data = {
        selectedPaymentBg: '',
        text: {
            desc: this.t('Выберите удобный для вас способ внесения пожертвования'),
            desc2: this.t('Изменить способ внесения пожертвования'),
        }
    }

    getPaymentMethods = () => {

        let items = []
        let itemConf = {
            xs: {span: 12},
            lg: {span: 8},
            xl: {span: 6},
        }


        for (let i = 1; i < 5; i++) {
            if (i!==2) {


                let $url = '/want-to-help/payments/' + i + '.png';
                let $img = this.renderImage($url, {width: '100%'})
                let style = this.getImgUrl('/img' + $url, (res) => {
                    return {
                        backgroundImage: "url(" + res + ")"
                    }
                })

                items.push((
                    <Col key={i} style={{display: ((i !== 3 && this.app().data.lang === 'en') ? 'none' : '')}}
                         onClick={() => {

                             this.caller.setStep(this.caller.data.step + (i === 4 ? 2 : 1))
                             this.caller.setPaymentMethod(i)
                             let SPB = <div className={'selected-payment-method pointer'} {...{
                                 style: style,
                                 onClick: () => this.caller.setStep(this.props.step)
                             }}></div>
                             this.set({selectedPaymentBg: SPB})
                             this.caller.set({selectedPaymentBg: SPB})

                         }
                         } {...itemConf}>
                        <div className={'item pointer'}>
                            <div className={'bg bg-' + i} style={style}>
                                {$img}
                            </div>
                        </div>
                    </Col>
                ))
            }
        }

        let rowConf = {
            gutter: {xs: 10, sm: 15, md: 20, lg: 25, xl: 30},
            justify: 'left',
            align: 'middle',
        };

        return <Row {...rowConf}>
            {items}
        </Row>;
    }


    render() {

        let show = this.caller.data.step === this.props.step ? true : false
        let paymentSystemButton = this.data.selectedPaymentBg


        return (
            <div>
                <div className={(show ? '' : 'hidden')}>
                    <p className={'font-slim hint'}>
                        {this.data.text.desc}
                    </p>
                    <div className={'payment-methods'}>
                        {this.getPaymentMethods()}
                    </div>
                </div>
                <div className={(show ? 'pointer hidden' : '')}>
                    <Row>
                        <Col xs={{span: 0}} sm={{span: 24}}>
                            {paymentSystemButton}
                        </Col>
                    </Row>
                    <div onClick={() => this.caller.setStep(this.props.step)}>
                        <Row>
                            <Col xs={{span: 7}} sm={{span: 0}}>
                                {paymentSystemButton}
                            </Col>
                            <Col xs={{span: 15, offset: 2}} sm={{span: 24, offset: 0}} className={'tal pointer'}>
                                <span className={'pointer hint font-slim l'}>
                                    {this.data.text.desc2}
                                </span>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        )
    }
}

export default Step1;
